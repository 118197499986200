@import "styles/form";
@import "styles/map";

.form_card {
    @include form_card;
    height: auto;
}

.form_title {
    font: normal 600 18px $rubik;
    color: var(--bc-gray-5);
    margin-bottom: 1rem;
    margin-left: 3.8rem;
}

.h5_info {
    @include text_info;
    font-size: 18px;
    font-weight: 600;
}

.text_info {
    @include text_info;
    margin-bottom: 0.5rem;
}

.info_password {
    text-decoration: underline;
}
