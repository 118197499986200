@import 'styles/form';
@import 'styles/map';

.form_card {
    @include form_card;
}

/* ----------- ICONS ----------- */
.button_cancel {
    @include button_cancel;
}

.button_active {
    @include button_active;
}

.button_inactive {
    @include button_inactive;
}

.text_info {
    @include text_info;
}

.text_title {
    font-size: 18px !important;
}

/* ----------- MAP ----------- */
.map_popup {
    @include map_popup;
}