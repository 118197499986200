@import "styles/utils";

/* ----------- BREADCRUMB ----------- */
.action_container {
    width: 100% !important;
    overflow-x: auto !important;
}

.action_sub_container {
    min-width: 535px;
}

.breadcrumb {
    padding: 0;
}

.breadcrumb_item {
    list-style: none;
    text-align: center;
    margin-left: 0.4rem;
}

.breadcrumb_span {
    color: var(--bc-success);
}

.breadcrumb_link {
    font: normal normal 15px $roboto;
    line-height: 16px;
    color: var(--bc-success);

    &:hover {
        color: #467474;
    }
}

.title {
    font: normal 500 24px $rubik;
    line-height: 28px;
    color: var(--bc-gray-5);
}
