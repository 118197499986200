@import "styles/form";

.form_card {
    @include form_card;
}

.text_info {
    @include text_info;
}

.text_info_title {
    @include text_info;
    font-weight: 500;
    float: right;
    margin-top: 0.7em;
}

/* ---------- INPUTS ---------- */
@mixin input_datetime {
    & .input {
        color: var(--bc-primary) !important;
    }
}

.input_datetime_left {
    @include input_datetime_left;

    & span:nth-child(n + 2) {
        padding: 0 !important;
    }
}

.input_datetime_right {
    @include input_datetime_right;
}

/* ---------- SELECT ---------- */

.select_title {
    color: var(--bc-primary);
}

.union_icon {
    width: 12px;
    height: 12px;
}

/* ----------- BUTTONS ----------- */
.button_cancel {
    @include button_cancel;
}

.badge_circle {
    display: inline-block;
    width: 22px !important;
    height: 22px !important;
    box-sizing: border-box;
    padding: 0.1em 0.3em;
    text-align: center;
    vertical-align: middle;

    border-radius: 20px;
}

.badge_success {
    background-color: var(--bc-success);
}
