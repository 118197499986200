@import 'styles/utils';


@mixin  hover-card {
    &:hover {
        box-shadow: 0px 22px 54px rgba(0, 0, 0, 0.219);
        transform: translateY(-4px);
        transition: transform 0.26s ease-in-out;
        filter: contrast(94%);
    }
}

.home_container {
    min-height: 100vh;
    background-color: var(--bc-primary);
}

.h1 {
    font: normal 500 48px $rubik;
    line-height: 57px;
    color: var(--bc-white);
}

.description {
    font: normal normal 18px $rubik;
    line-height: 21px;
    color: var(--bc-white);
}

.card_container {
    background-color: var(--bc-white) !important;
    border-radius: 12px !important;
    
    @include hover-card;
}

.card_logout_container {
    background-color: var(--bc-primary) !important;
    box-sizing: border-box;
    border: 2px solid #70a3a3 !important;
    border-radius: 12px !important;

    & .title {
        color: var(--bc-white) !important;
    }

    @include hover-card;
}

.card_body {
    width: 211px;
    height: 243px;

    cursor: pointer;

    &:hover {
        filter: blur(20%);
    }
}

.icon {
    width: 40px;
    height: 40px;
}

.title {
    font: normal normal 24px $roboto;
    line-height: 28px;
    color: var(--bc-primary);
}
