@import 'styles/utils';
@import 'styles/form';


/* ----------  POPOVER ---------- */
.popover_map_container {
    background-color: #f8f8f8 !important;
    border: 0 !important;
    border-radius: 5px;
    box-shadow: 0 2.4px 8px 1px rgba(51, 78, 145, 0.055) !important;
    
    z-index: 4000 !important;

    & div::before,
    & div::after {
        
        border-top-color: var(--bc-white) !important;
    }
}


.popover_text_title {
    font: normal 500 14px $rubik;
    line-height:  17px;
    color: var(--bc-gray-5);
    margin: 0 !important;
}

.popover_text_info {
    font: normal normal 12px $rubik;
    line-height: 14px;
    color: var(--bc-gray-3);
    margin: 0 !important;
}

/* ----------  MAP ---------- */
.container_map {
    height: 200px;
}