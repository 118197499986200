@import 'styles/utils';
@import "styles/form";

.form_card {
    @include form_card;
}

.text_info {
    @include text_info;
}

.text_info_title {
    @include text_info;
    font-weight: 500;
    float: right;
    margin-top: 0.7em;
}

/* ---------- INPUTS ---------- */
@mixin input_datetime {
    & .input {
        color: var(--bc-primary) !important;
    }
}

.input_datetime_left {
    @include input_datetime_left;

    & span:nth-child(n + 2) {
        padding: 0 !important;
    }
}

.input_datetime_right {
    @include input_datetime_right;
}

/* ---------- SELECT ---------- */

.select_title {
    color: var(--bc-primary);
}

.union_icon {
    width: 12px;
    height: 12px;
}

/* ----------- BUTTONS ----------- */
.button_cancel {
    @include button_cancel;
}

.button_active {
    @include button_active;
}

.button_inactive {
    @include button_inactive;
}

/* ----------  POPOVER ---------- */
.popover_map_container {
    z-index: 2000;
    border: 0 !important;
    border-radius: 5px;
    box-shadow: 0 2.4px 8px 1px rgba(51, 78, 145, 0.055) !important;

    & div::before,
    & div::after {
        
        border-top-color: var(--bc-white) !important;
    }
}


.popover_text_title {
    font: normal 500 14px $rubik;
    line-height:  17px;
    color: var(--bc-gray-5);
    margin: 0 !important;
}

.popover_text_info {
    font: normal normal 12px $rubik;
    line-height: 14px;
    color: var(--bc-gray-3);
    margin: 0 !important;
}

.union_icon {
    width: 12px;
    height: 12px;
}

.badge_circle {
    display: inline-block;
    width: 22px !important;
    height: 22px !important;
    box-sizing: border-box;
    padding: 0.1em 0.3em;
    text-align: center;
    vertical-align: middle;

    border-radius: 20px;
}

.badge_success {
    background-color: var(--bc-success);
}
