@import 'styles/utils';

/* ----------- CARD ----------- */
.card_container {
    width: 446px;
    height: 405px;

    background: var(--bc-white);
    border-radius: 12px !important;
}

/* ----------- FORM ----------- */
.form_group_login {
    margin-bottom: 2rem !important; 
}

.form_group_password {
    margin-bottom: 1.6rem !important; 
}