@import 'styles/utils';

.textarea {
    width: 100%;
    box-sizing: border-box;
    border: 1px solid var(--bc-primary);
    border-radius: 5px;
    padding: 1em;

    &:focus, &:focus-visible {
        outline: none;
        border: 1px solid var(--bc-primary);
    }

    &::placeholder {
        font: normal normal 14px $rubik;
        line-height: 17px;
        color: var(--bc-gray-2);
    }
}