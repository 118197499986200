@import 'styles/utils';
@import 'styles/datatable';

.table_title {
    @include text_table_title;
}

.union_icon {
    width: 12px;
    height: 12px;
}

.badge_circle {
    display: inline-block;
    width: 22px;
    height: 22px;
    box-sizing: border-box;
    padding: 0.1em 0.2em;
    text-align: center;
    vertical-align: middle;

    border-radius: 20px;
    margin-left: 1em;
    margin-top: 0.1em;
}

.badge_success {
    background-color: var(--bc-success);
}
