.spinner_container {
    background-color: transparent;

    position: fixed;
    bottom: 0;
    left: 0.3rem;
}

.custom_spinner {
    color: #293c6290;
    width: 1.2rem !important;
    height: 1.2rem !important;

    font-size: 0.9rem;
}