@import 'styles/utils';
@import 'styles/form';

.form_card {
    @include form_card;
}

.text_info {
    @include text_info;
}

.text_info_title {
    @include text_info;
    font-weight: 500;
    float: right;
    margin-top: .7em;
}

/* ---------- INPUTS ---------- */
@mixin input_datetime {
    & .input {
        color: var(--bc-primary) !important;
    }
}

.input_datetime_left {
    @include input_datetime_left;

    & span:nth-child(n + 2) {
        padding: 0 !important;
    }
}

.input_datetime_right {
    @include input_datetime_right;
}

/* ----------- ICONS ----------- */
.button_cancel {
    @include button_cancel;
}