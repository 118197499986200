/* ----------- FONTS ----------- */
$rubik: "Rubik", sans-serif;
$roboto: "Roboto", sans-serif;

/* ----------- MIXINS ----------- */
@mixin linear_gradient($background...) {
    background: -webkit-linear-gradient($background);
    background: -moz-linear-gradient($background);
    background: -o-linear-gradient($background);
    background: linear-gradient($background);
}

@mixin transition($properties...) {
    -webkit-transition: $properties;
    -moz-transition: $properties;
    -o-transition: $properties;
    transition: $properties;
}

@mixin user_select($value) {
    -webkit-user-select: $value;
    -moz-user-select: $value;
    -ms-user-select: $value;
    user-select: $value;
}
