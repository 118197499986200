@import 'styles/utils';

.input {
    height: 50px;
    font: normal 500 14px $rubik;
    color: var(--bc-gray-4) !important;
    background-color: var(--bc-white) !important;
    border: 1px solid var(--bc-blue-3) !important;
    border-left: none !important;

    &:focus, &:focus-visible {
        outline: none !important;
        border: 1px solid var(--bc-blue-3) !important;
        border-left: none !important;
        box-shadow: none !important;
    }

    &::placeholder {
        font: normal normal 14px $rubik;
        color: var(--bc-gray-2) !important;
        font-size: 14px;
        line-height: 17px;
    }

}

/* ----------- ICON ----------- */
.icon {
    width: 16px;
    height: 16px;
}

.box_icon_left {
    width: auto;
    height: auto;
    background-color: var(--bc-white) !important;
    border: 1px solid var(--bc-blue-3) !important;
    padding: .4rem .40rem !important;
    border-right: none !important;
    border-top-left-radius: 5px !important;
    border-bottom-left-radius: 5px !important;
}

.box_icon_right {
    width: auto;
    height: auto;
    background-color: var(--bc-white) !important;
    border: 1px solid var(--bc-blue-3) !important;
    border-top-right-radius: 5px !important;
    border-bottom-right-radius: 5px !important;
    padding: 0.370rem 1rem !important;

    cursor: pointer;

    &:focus, &:focus-visible {
        box-shadow: none !important;
    }
}