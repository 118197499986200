@import 'styles/utils';

.link {
    font: normal normal 14px $roboto;
    line-height: 16px;
    color: var(--bc-primary);

    &:hover {
        color: var(--bc-blue-2);
    }
}