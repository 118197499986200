@import 'styles/utils';
@import 'styles/form';


.box_form_card {
    flex: 0 0 auto !important;
    width: 49% !important;
}

.form_card {
    @include form_card;
}
.text_info {
    @include text_info;
}

.text_info_title {
    @include text_info;

    font-weight: 500;
    color: var(--bc-gray-5) !important;
}

/* ---------- INPUTS ---------- */
.input_datetime {
    @include input_datetime;
}

.input_datetime_left {
    @include input_datetime_left;

    & span:nth-child(n + 2) {
        padding: 0 !important;
    }
}

.input_datetime_right {
    @include input_datetime_right;
}

/* ----------- ICONS ----------- */
.button_cancel {
    @include button_cancel;
}

.button_active {
    @include button_active;
}

.button_inactive {
    @include button_inactive;
}


/* SELEÇÃO MULTIPLA */
.option_title {
    font: normal bold 15px $rubik;
    line-height: 18px;
    color: var(--bc-primary);
    margin-bottom: .2em;
}

.option_info {
    font: normal normal 14px $rubik;
    line-height: 17px;
    color: #828262;
    margin-bottom: .3em;
}


/* DIV MULTIPLE SELECT */
.div_multiple_select {
    height: 50px;
    font: normal 500 14px $rubik;
    color: var(--bc-gray-4) !important;
    background-color: var(--bc-white) !important;
    border: 1px solid var(--bc-primary);
    border-radius: 5px;
    padding-left: 0.8rem;
    padding-right: 0.8rem;

    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;

    cursor: pointer;

    .placeholder {
        font: normal normal 14px $rubik;
        color: var(--bc-secondary) !important;
        font-size: 14px;
        line-height: 17px;
        display: flex;
        overflow: hidden;

        @include user-select(none);
    }

    .chevron_icon {
        flex-shrink: 0;
        width: 22px;
        height: 22px;
        float: right;

        @include user-select(none);
    }
}


/* MODAL de busca avançada */
.box_show_search {
    height: 400px;
    font: normal 500 14px $rubik;
    line-height: 17px;
    background-color: var(--bc-gray-light);
    color: var(--bc-primary);
    overflow-y: auto;

    & input {
       opacity: 1 !important;
       margin-right: 0.5rem;
    }
}