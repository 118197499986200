@import "styles/utils";
@import "styles/datatable";
@import "styles/form";

.input_datetime_left {
    @include input_datetime_left;
}

.input_datetime_right {
    @include input_datetime_right;
}

/* ------------ TABLE ------------ */
.table_title {
    @include text_table_title;
}

.datetime {
    @include text_datetime;

    img {
        margin-right: 0.3rem;
    }

    .table_icon:nth-child(n + 2) {
        margin-left: 0.5rem;
    }
}

.badge_status {
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
}

.document_url {
    display: block;
    font: normal normal 14px $rubik;
    color: var(--bc-blue-2);
    text-decoration: underline;

    padding: 0.1rem 0;
    margin: 0 !important;

    cursor: pointer;
}

@mixin buttons_action($color) {
    background-color: transparent !important;
    border: 0 !important;
    box-shadow: none !important;
    text-decoration: underline;

    &:hover,
    &:focus {
        background-image: none !important;
        color: $color;
        filter: brightness(0.7) !important;
    }

    &:active {
        color: $color;
        filter: brightness(1.1) !important;
    }
}

.buttons_actions {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    gap: 0.5rem;

    .button_action_approved {
        @include buttons_action(var(--bc-green-2));

        color: var(--bc-green-2);
    }

    .button_action_disapproved {
        @include buttons_action(var(--bc-red-2));

        color: var(--bc-red-2);
    }
}

/* ---------- MODAL APPROVED AND DISAPPROVED ---------- */
@mixin buttons_modal {
    margin-top: 0.5rem !important;
    padding: 0.7rem 1.8rem !important;
}

.button_modal_cancel {
    @include buttons_modal;
    @include button_cancel;
}

.button_modal_approved {
    @include buttons_modal();

    background-color: var(--bc-green-2) !important;

    &:hover {
        background-color: var(--bc-green-2) !important;
        filter: contrast(109%);
    }
}

.button_modal_disapproved {
    @include buttons_modal;

    background-color: var(--bc-red-3) !important;

    &:hover {
        background-color: var(--bc-red-3) !important;
        filter: contrast(125%);
    }
}

.box_show_search {
    height: 200px;
    font: normal 500 14px $rubik;
    line-height: 17px;
    background-color: var(--bc-gray-light);
    color: var(--bc-primary);
    overflow-y: auto;

    & input {
        opacity: 1 !important;
        margin-right: 0.5rem;
    }

    & .box_item_selected {
        background-color: var(--bc-gray-1);
    }
}

.label_modal {
    color: var(--bc-gray-3);
    font-size: 15px !important;
    margin-bottom: 0.8rem !important;
}

.text_info_title {
    @include text_info;
    font-weight: 500;
    float: right;
    margin-top: 0.7em;
}

/* ---------- MODAL DETAILS PHOTO ---------- */
.modal_content_photo {
    background-color: #292929b0 !important;
    border-radius: 0 !important;
}

.modal_photo_body {
    background-color: transparent;
    color: var(--bc-white);
    overflow: hidden !important;
}

.container_modal_photo {
    width: 30%;
    height: 550px;
    max-height: 550px !important;
    transition: transform 0.3s ease-in-out;

    &.zoom_in {
        cursor: zoom-in;
        transform: scale(1);
    }

    &.zoom_out {
        cursor: zoom-out;
        transform: scale(1.5);
    }

    & img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
}