@import "styles/utils";

.switch_container {
    width: 110px !important;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    cursor: pointer;
    padding: 0 0.2em;

    @include linear_gradient(150deg, var(--bc-gray-3), var(--bc-gray-5));
    @include transition(background 0.5s ease-in-out);

    &,
    .switch_check {
        width: 100%;
        height: 2.3em;
        min-height: 30px;
        border-radius: 20px;
        cursor: pointer;
    }

    &.active {
        @include linear_gradient(150deg, var(--bc-green-3), var(--bc-green-4));
        justify-content: flex-start;
    }
}

.switch_check {
    position: absolute;
    z-index: 100;
    -webkit-appearance: none;
    appearance: none;

    &:focus,
    &:focus-visible {
        outline: none !important;
        box-shadow: none !important;
    }
}

.switch {
    width: 27px;
    height: 28px;
    background-color: var(--bc-white) !important;
    border-radius: 20px;
    position: absolute;
    top: auto;
    bottom: auto;
    z-index: 90;
    @include transition(left 0.2s ease-in-out);

    &.inactive {
        left: 2.9%;
    }

    &.active {
        left: 72.8%;
    }
}

.switch_label {
    z-index: 80;
    font: normal 500 14px $roboto;
    line-height: 16px;
    color: var(--bc-white);

    @include transition((margin-left 0.2s ease-in-out), (margin-right 0.2s ease-in-out));

    &.inactive {
        margin-right: 20px;
    }

    &.active {
        margin-left: 20px;
    }
}
