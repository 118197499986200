@import "styles/utils";

.button {
    height: 100%;
    font: normal 500 14px $roboto !important;
    line-height: 16px;
    background-color: var(--bc-primary) !important;
    box-shadow: 0px 2px 5px 1px rgba(51, 78, 145, 0.2);
    border: 0 !important;
    border-radius: 20px !important;
    padding: 0.78rem 0.75rem !important;

    &:hover {
        background-color: var(--bc-blue-2) !important;
        background-image: linear-gradient(transparent, rgba(0, 0, 0, 0.05) 90%, rgba(0, 0, 0, 0.1));
    }

    &:focus,
    &:focus-visible {
        outline: none !important;
        border: 0 !important;
        box-shadow: none !important;
    }
}
