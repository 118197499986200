@import "styles/utils";

.badge_container {
    display: flex;
    justify-content: center;
    align-items: center;
    white-space: nowrap;

    font: normal 600 12px $rubik;
    line-height: 12px;

    padding: 0.3em 1em;

    &.primary {
        color: var(--bc-gray-5) !important;
        background-color: var(--bc-gray-2);
    }

    &.success {
        color: var(--bc-gray-5) !important;
        background-color: var(--bc-green-1);
    }

    &.info {
        color: var(--bc-white) !important;
        background-color: var(--bc-blue-2);
    }

    &.warning {
        color: var(--bc-gray-5) !important;
        background-color: var(--bc-yellow);
    }

    &.danger {
        color: var(--bc-white) !important;
        background-color: var(--bc-red-1);
    }
}

.badge_pill {
    border-radius: 50rem;
}

.badge_rounded {
    border-radius: 0.25rem;
}

.box_button_close {
    display: inline-block;
    margin-left: 0.4em;
    padding: 0.2em 0.35em;

    &,
    & button {
        background-color: #e5e5e5;
        border-radius: 18px;
    }

    & button {
        width: 10px;
        height: 10px;
        margin-bottom: 1px;
        margin-left: 0.1px;
        margin-right: 0.1px;
        padding: 0;
        vertical-align: middle;
        box-sizing: content-box;
        color: var(--bc-gray-1) !important;

        &:focus,
        &:focus-visible {
            background-color: #e5e5e5 !important;
            color: var(--bc-gray-1) !important;
            box-shadow: none !important;
        }
    }
}
