@mixin text_table_title {
    font: normal 500 14px $rubik !important;
    line-height: 17px;
    color: var(--bc-black) !important;
}

@mixin text_datetime {
    display: inline-block;
    font: normal normal 12px $rubik !important;
    line-height: 14px;
    color: var(--bc-primary) !important;
}
