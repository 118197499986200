@import 'styles/utils';

.navbar {
    background-color: var(--bc-primary);
    padding: .7rem .3rem !important;
}

.logo_name {
    color: var(--bc-white) !important;
    font: normal 400 20px $roboto;
    margin-top: .5em !important;
    margin-bottom: 0 !important;
    margin-left: 0.5rem;
    vertical-align: middle;
}

.logo {
    cursor: pointer;
}

.link {
    color: var(--bc-white) !important;
    padding: 0.6em 1.4em !important;
    border-radius: 21.5px;
    vertical-align: middle;

    &.active {
        background-color: var(--bc-blue-2) !important;
    }
}

.icon {
    width: 15px;
    height: 15px;
    margin-right: 0.8em;
}

.button_logout {
    background-color: var(--bc-blue-1) !important;
    border: 0 !important;
    border-radius: 30px !important;
    padding: 16px 17px !important;

    &:focus, &:focus-visible, &:active {
        border: 0 !important;
        outline: none !important;
        box-shadow: none !important;
    }
}

.logout_icon {
    width: 15px;
    height: 15px;
}
