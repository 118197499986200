@import "styles/utils";
@import "styles/datatable";
@import "styles/form";

.input_datetime_left {
    @include input_datetime_left;
}

.input_datetime_right {
    @include input_datetime_right;
}

/* ------------ TABLE ------------ */
.table_title {
    @include text_table_title;
}

.datetime {
    @include text_datetime;

    img {
        margin-right: 0.3rem;
    }

    .table_icon:nth-child(n + 2) {
        margin-left: 0.5rem;
    }
}

.badge_circle {
    display: inline-block;
    width: 22px;
    height: 22px;
    box-sizing: border-box;
    padding: 0.15em 0.15em;
    text-align: center;
    vertical-align: middle;

    border-radius: 20px;
    margin-left: 1em;
    cursor: help;
}

.badge_success {
    background-color: var(--bc-success);
}

.popover_message {
    border: 0;
    border-radius: 15px !important;

    &::before,
    &::after {
        border-top-color: var(--bc-blue-3) !important;
    }

    & .popover_header {
        background-color: var(--bc-white-ice-2);
    }

    & .popover_body {
        font-size: 0.9rem;
        font-weight: 500;
        color: var(--bc-white-ice-2);
        background-color: var(--bc-primary) !important;
        border-radius: 15px !important;
    }
}

/* ----------- ICONS ----------- */
.union_icon {
    width: 11px;
    height: 11px;
    margin-bottom: 0.1rem;
    margin-left: auto;
    margin-right: auto;
}

/* ----------- TABS ----------- */
.data_table_custom {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
}

.tabs_container {
    width: 98%;
    border-bottom: 0 !important;

    & li {
        $border_styles: 1px solid var(--bc-gray-3) !important;

        border-radius: 5px 5px 0 0 !important;
        border-top: $border_styles;
        border-left: $border_styles;
        border-right: $border_styles;

        &,
        & button {
            font: normal 500 14px $roboto;
            line-height: 16px;
            color: var(--bc-gray-3) !important;
            background-color: var(--bc-white) !important;
        }

        & button {
            padding: 0.8em 2em;

            &.active {
                color: var(--bc-white) !important;
                background-color: var(--bc-primary) !important;
                border: 1px solid var(--bc-primary) !important;
            }
        }
    }
}

/* ----------- LOCATION ----------- */
.popover_location {
    min-width: 300px;
    max-width: 420px;
}
