@import 'styles/utils';
@import 'styles/datatable';

.table_title {
    @include text_table_title;
}

.datetime {
    @include text_datetime;
}

.image_thumbnail {
    width: 50px;
    max-height: 50px;
}
