@import "styles/utils";

/* ----------- ACCORDION ----------- */
/* .accordion_body {
}
 */
.container_dataTable {
    flex: 0 0 auto;
    width: 98%;
    margin-bottom: 1.6rem;
    background-color: var(--bc-white) !important;
    box-shadow: 0px 2px 5px 1px rgba(51, 78, 145, 0.2) !important;
    border-radius: 12px !important;
    overflow: hidden;
}

.header {
    border-radius: 20px !important;
    border-bottom-left-radius: 0% !important;
    border-bottom-right-radius: 0% !important;

    & button {
        background-color: var(--bc-white) !important;
        box-shadow: none !important;
        color: #000000 !important;
    }
    & button:focus,
    & button:focus-visible {
        box-shadow: none;
        outline: 0;
        border-color: var(--bc-blue-2);
    }
}

.header_title {
    font: normal 500 18px $rubik;
    line-height: 21px;
    color: var(--bc-gray-5) !important;
    margin-bottom: 0;
}

.box_button_search {
    height: 40px !important;
}

/* ----------- TABLE ----------- */
.table {
    margin-bottom: 0 !important;
    min-width: 765px;

    & thead {
        background-color: var(--bc-gray-light) !important;
    }

    & thead td {
        font: normal 500 14px $rubik !important;
        line-height: 17px;

        color: var(--bc-gray-5) !important;
        cursor: pointer;
        vertical-align: middle;
    }

    & td {
        max-width: 100%;
        width: fit-content;
        padding: 0.9rem 0.5rem !important;
        vertical-align: baseline;
    }

    & tbody .item_selected {
        background-color: var(--bc-gray-light) !important;
    }

    & tbody td {
        font: normal normal 14px $rubik;
        
        line-height: 17px;
        color: var(--bc-black);
        text-align: justify;
        cursor: pointer;

        padding-left: 1.5rem !important;
    }
}

.table_empty {
    width: 100%;
    padding: 1rem 0;
    font: normal 600 14px $rubik;
    text-align: center;
}

.icon_table {
    vertical-align: middle;
}

.empty_custom {
    padding: 0.5rem;
    text-align: center;
    width: 100%;
}

/* -----------  PAGINATION ----------- */
.pagination_box {
    background-color: var(--bc-white-ice-2) !important;
    padding: 1.3rem 0.5rem;
}

.paginate_item {
    width: 31px;
    height: 31px;
    background-color: var(--bc-white) !important;
    border: 1px solid var(--bc-gray-light) !important;
    border-radius: 20px;
    box-sizing: border-box;
    overflow: hidden;
    margin-left: 5px;

    font: normal 500 12px $roboto !important;
    line-height: 14px;
    border: 0;
    color: var(--bc-gray-3) !important;
    padding: 0;

    &:focus,
    &:focus-visible {
        outline: none !important;
        border: 0 !important;
        box-shadow: none !important;
    }

    &.active {
        background-color: var(--bc-primary) !important;
        border: 1px solid var(--bc-primary) !important;
        color: var(--bc-white) !important;
    }

    &:active {
        filter: brightness(0.8) !important;
    }
}

.paginate_options {
    width: 31px;
    height: 31px;
    box-sizing: border-box !important;
    border: 1px solid var(--bc-primary) !important;
    border-radius: 20px !important;
    background-color: var(--bc-white) !important;
    overflow: hidden !important;
    margin-left: 5px !important;

    font: normal 500 20px $roboto !important;
    line-height: 15px !important;
    color: var(--bc-primary) !important;

    &:active {
        filter: brightness(0.9) !important;
    }

    &:disabled {
        background-color: var(--bc-gray-2) !important;
    }

    & .paginate_arrow {
        height: fit-content;
        width: fit-content;
        display: block;
        font-size: 1.3rem;

        padding: 0;
        margin: 0;
        margin-bottom: 0.1em !important;
    }
}

.select_box {
    margin-right: 1rem;

    & select {
        color: var(--bc-primary) !important;
    }
}

.form_select {
    border: 1px solid var(--bc-primary) !important;

    &:focus,
    &:focus-visible,
    &:active {
        outline: none !important;
        border: 1px solid var(--bc-primary) !important;
        box-shadow: none !important;
    }
}
