@import "styles/utils";

.multiple_select {
    height: 50px;
    font: normal 500 14px $rubik;
    color: var(--bc-gray-4) !important;
    background-color: var(--bc-white) !important;
    border: 1px solid var(--bc-primary);
    border-radius: 5px;
    padding-left: 0.8rem;
    padding-right: 0.8rem;

    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;

    cursor: pointer;
}

.placeholder {
    font: normal normal 14px $rubik;
    color: var(--bc-secondary) !important;
    font-size: 14px;
    line-height: 17px;
    display: flex;
    overflow: hidden;

    @include user-select(none);
}

.chevron_icon {
    flex-shrink: 0;
    width: 22px;
    height: 22px;
    float: right;

    @include user-select(none);
}

/* ----------- DROPDOWN ----------- */
.dropdown {
    width: 100%;
    height: auto;
    max-height: 280px;
    background-color: var(--bc-white);
    box-sizing: border-box;
    border: 1px solid var(--bc-primary);
    border-radius: 5px;
    padding: 0.8em;

    display: none;
    position: absolute;
    top: 110%;
    left: 0;
    z-index: 2000;
    overflow-y: auto;

    &.active {
        display: block;
    }
}

.dropdown_item {
    display: flex;
    align-items: center;
    gap: 1em;
    border-radius: 5px;
    padding: 0.4em .3em;
    cursor: pointer;

    font: normal 500 14px $rubik;
    line-height: 17px;

    & input {
       opacity: 1 !important;
       margin-bottom: .1rem;
    }

    & span {
        width: 100%;
        color: var(--bc-primary);
    }

    &:hover {
        background-color: var(--bc-gray-light);
    }
}