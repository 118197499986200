/* ----------- ICONS ----------- */
@import 'styles/utils';

/* ----------- BUTTONS ----------- */
@mixin button_eye {
    background-color: var(--bc-gray-3) !important;

    &:hover {
        background-color: var(--bc-gray-3) !important;
        filter: opacity(94%);
    }
}

@mixin button_cancel {
    box-shadow: inset 0px 0px 10px #33333340 !important;

    &:hover {
        filter: contrast(150%) !important;
    }

    &:active, &:focus, &:focus-visible {
        box-shadow: inset 0px 0px 13px #33333340 !important;
    } 

    &,
    &:hover, &:focus {
        background-color: transparent !important;
        border: 1px solid var(--bc-gray-3) !important;
        color: var(--bc-gray-3) !important;
    }
}

@mixin button_active {
    background-color: #09c72c !important;

    &:hover {
        background-color: #09c72c !important;
        filter: contrast(116%);
    }
}

@mixin button_inactive {
    background-color: var(--bc-gray-3) !important;

    &:hover {
        background-color: var(--bc-gray-3) !important;
        filter: opacity(92%);
    }
}

/* ----------- CARD FORM ----------- */
@mixin form_card {
    background-color: var(--bc-white) !important;
    box-shadow: 0 2px 5px 1px rgba(51, 78, 145, 0.2) !important;
    border-radius: 12px !important;
    padding: .4rem .8em;
}

/* ----------- TEXT -----------  */
@mixin text_info {
    font: normal normal 14px $rubik;
    line-height: 17px;
    color: var(--bc-gray-3);
}

/* ---------- INPUT ---------- */
@mixin input_datetime {
    & input {
        color: var(--bc-primary) !important;
    }
}

@mixin input_datetime_left {
    &,
    .box_icon_right {
        border-right: none !important;
        border-top-right-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
    }

    @include input_datetime;
}

@mixin input_datetime_right {
    &,
    .box_icon_left {
        border-left: none !important;
        border-top-left-radius: 0 !important;
        border-bottom-left-radius: 0 !important;
    }

    @include input_datetime;
}