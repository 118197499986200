@import "styles/utils";
@import "styles/datatable";
@import "styles/form";

/* ---------- CARD ---------- */
.card_statistic {
    width: 250px;
    height: auto;

    border: 1px solid #bbbbbb;
    border-radius: 10px !important;
    padding: 0.5rem !important;
    color: var(--bc-white);
    position: relative;

    & .title {
        font: normal 600 15px $rubik;
        margin-bottom: 0.8rem;
    }

    & .value {
        font: normal 500 20px $rubik;
        margin-bottom: 0.8rem;
    }

    &.today {
        @include linear-gradient(185deg, #3e4b68, #293c62);
    }

    &.month {
        @include linear-gradient(185deg, #6e75ff, #575fff);
    }

    &.lastMonth {
        @include linear-gradient(185deg, #ff6e6e, #ff5656);
    }

    &.year {
        @include linear-gradient(185deg, #ab5dff, #9532ff);
    }

    &.period_access {
        @include linear-gradient(185deg, #1ec73d, #09c72c);
    }

    &.total {
        background-color: #f8f8f8;
        color: var(--bc-gray-4);
    }

    & .icon {
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        background-color: var(--bc-white);

        position: absolute;
        top: 15px;
        right: 15px;

        &.total {
            background-color: var(--bc-orange-3);
        }

        & img,
        & .icon_skeleton {
            width: 40%;
            height: 40%;
            color: var(--bc-gray-2) !important;

            object-fit: contain;
        }
    }
}

.input_datetime_left {
    @include input_datetime_left;
}

.input_datetime_right {
    @include input_datetime_right;
}

/* ---------- SKELETON  CARD ---------- */
.card_skeleton {
    height: 120px;
    background-color: #f5f5f5;
}

.skeleton_title {
    width: 50%;
    color: var(--bc-gray-3);
}

.skeleton_value {
    width: 20%;
    color: var(--bc-gray-2);
}

/* ---------- LIST ---------- */

.container_list {
    width: 95%;

    .list_title {
        font: normal 600 18px $rubik;
        color: var(--bc-gray-4);
        margin-bottom: 1rem;
    }

    .list {
        // max-height: 402px;
        // overflow-y: auto;
        padding: 0.8rem;

        .list_item {
            min-width: 991px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            color: var(--bc-gray-5);

            & span {
                width: 250px;

                &:nth-child(1) {
                    font-weight: 600;
                }
            }
        }
    }
}

.button_infinite {
    background-color: transparent !important;
    color: var(--bc-primary) !important;
    border: 1px solid transparent !important;

    &:hover {
        color: var(--bc-primary) !important;
        background-color: transparent !important;
        border: 1px solid var(--bc-primary) !important;
    }

    &:active,
    &:focus {
        color: var(--bc-primary) !important;
        border: 1px solid var(--bc-primary) !important;
    }
}
