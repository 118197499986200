@import "styles/form";

.form_card {
    @include form_card;
}

.text_info {
    @include text_info;
}

.text_info_title {
    @include text_info;
    font-weight: 500;
    float: right;
    margin-top: .7em;
}

/* ----------- BUTTONS ----------- */
.button_cancel {
    @include button_cancel;
}

.button_active {
    @include button_active;
}

.button_inactive {
    @include button_inactive;
}