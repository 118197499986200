@import 'styles/utils';

/* ----------- DROPDOWN OPTIONS ----------- */
.box_show {
    transition: height 1s ease-in-out;
}

.dropdown_option {
    position: absolute;
    top: 50px;
    left: 1%;
    width: 120px;
    z-index: 20;
    background-color: #f0f0f0 !important;
    padding: 2px !important;
    border-radius: 5px;
    margin: 0 !important;
    border: 1px solid var(--bc-gray-1);
}

.dropdown_item {
    width: 100%;
    font: normal 400 16px $rubik;
    list-style: none;
    text-align: center;
    padding: 0.3rem 0.2em !important;
    box-sizing: border;
    cursor: pointer;

    &:nth-child(1) {
        border-bottom: 1px solid var(--bc-gray-1);
    }

    &:hover {
        background-color: var(--bc-gray-1) !important;
    }
}

/* ----------- IMAGE PREVIEW ----------- */
.form_inputFIle {
    margin-bottom: 2.4rem;
}

.box_upload_image {
    position: relative;

    &.active_upload {
        &::before {
            content: "";
            width: 100%;
            height: 100%;

            position: absolute;
            background-image: initial;
            background-color: rgba(24, 26, 27, 0.021);
            box-shadow: rgba(24, 26, 27, 0.116) 0px 0px 15px inset;
            backdrop-filter: blur(0.2px);
            border-radius: 5px;

            overflow: hidden;
            z-index: 1;
        }
    }

    & image {
        z-index: 2;
    }

    & .button_inactive {
        z-index: 2;
    }
}

.box_image {
    width: 90%;
    height: 90px;

    & img {
        max-height: 70px;
        margin-top: 0.6em !important;
        margin-left: 0.3em !important;
    }
}