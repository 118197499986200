.icon {
    width: 17px;
    height: 14px;
    vertical-align: middle;
    margin-bottom: 0.1em !important;
}

.icon_left {
    margin-left: 1em !important;
    border-radius: 20%;
}

.icon_placeholder {
    margin-left: 0.3rem;

    & span {
        display: inline-block !important;
        width: 18px !important;
        height: 20px !important;

        padding: 0rem !important;
    }
}
