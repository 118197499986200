@import "styles/form";

.form_card {
    @include form_card;
}

.input_password {
    & span:nth-child(n+2) {
        cursor: pointer !important;
        border-left: 2px solid var(--bc-primary) !important;
    }
}

/* ----------- BUTTONS ----------- */
.button_cancel {
    @include button_cancel;
}

.button_active {
    @include button_active;
}

.button_inactive {
    @include button_inactive;
}