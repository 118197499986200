.switch_simples_container {
    & input {
        width: 2.6em !important;
        border: 1px solid var(--bc-gray-2);
        box-shadow:  0 2px 10px #55555588;
        cursor: pointer;
    }

    & input:checked {
        background-color: var(--bc-green-3) !important;
        border: none;
        box-shadow:  0 2px 10px #55555588;
    }

    & input:focus, & input:focus-visible {
        outline: 0 !important;
        box-shadow:  0 2px 10px #55555588;
    }
}