@import 'styles/utils';

.modal {
    & > div > div {
        box-shadow: 0px 2px 5px 1px rgba(46, 46, 46, 0.2) !important;
        border-radius: 8px !important;
        border: 0 !important;
        padding: 0 !important;
    }
}

.modal_body {
    padding: 1.3rem 1.8rem 1.5rem 1.8rem !important;

    & .modal_content {
        height: 100%;
    }
}

.modal_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.2rem;

    & .modal_title {
        font: normal 500 23px $rubik;
        line-height: 28px;
        margin-bottom: 0 !important;
    }

    & .modal_close_button {
        background-size: 12px !important;
        box-sizing: border-box;
        border-radius: 20px;
        padding: .83rem;

        &.custom_border_gray {
            border: 2px solid var(--bc-gray-2);
        }

        &.custom_border_light {
            border: 2px solid var(--bc-white);
        }

        &:focus, &:focus-visible {
            outline: 0 !important;
            border: 2px solid #a5a5a5 !important;
            box-shadow: none !important;
        }
    }
}

.modal_buttons {
    display: flex;
    justify-content: flex-end;
    gap: .6rem;
}
