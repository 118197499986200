@import 'styles/utils';

.card_container {
    background-color: var(--bc-white) !important;
    border-radius: 12px !important;
}

.card_body {
    padding: 2rem 1.3rem 2rem 2rem !important;
}

.img_illustration {
    width: 100%;
    height: 243px;
}

.h1 {
    font: normal 500 24px $rubik;
    line-height: 28px;
    color: var(--bc-gray-5);
}

.description {
    font: normal normal 14px $rubik;
    line-height: 17px;
    color: var(--bc-gray-3);
}