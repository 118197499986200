:root {
    --bc-primary: #293c62;
    --bc-secondary: #606062;
    --bc-success: #70a3a3;
    --bc-white: #ffffff;

    --bc-blue-1: #6c9ab6;
    --bc-blue-2: #334e91;
    --bc-blue-3: #293c62;

    --bc-white-ice-2: #F8F8F8;
    
    --bc-gray-light: #F2F2F2;
    --bc-gray-1: #C4C4C4;
    --bc-gray-2: #a8a8a8;
    --bc-gray-3: #606062;
    --bc-gray-4: #505050;
    --bc-gray-5: #333333;

    --bc-black: #000000;

    --bc-green-1: #00FF2E;
    --bc-green-2: #00EA2A;
    --bc-green-3: #00CD25;
    --bc-green-4: #00A11D;

    --bc-red-1: #FF0000;
    --bc-red-2: #EA0000;
    --bc-red-3: #CD0000;
    --bc-red-4: #A10000;

    --bc-yellow: #FFD700;
    --bc-orange-1: #FFA500;
    --bc-orange-2: #FF8C00;
    --bc-orange-3: #FF7F00;
}

.remove-focus {
    &:focus,
    &:focus-visible {
        border: 0 !important;
        outline: 0 !important;
        box-shadow: none !important;
    }
}

/* --- SUBSTITUINDO OS ÍCONE DO ACCORDION DO BOOTSTRAP --- */
.accordion-custom .accordion-button:not(.collapsed)::after {
    transform: rotate(180deg);
}

.accordion-custom .accordion-button::after {
    flex-shrink: 0;
    width: 25px;
    height: 25px;
    float: right;
    margin-right: 18px;
    content: "";
    background-image: url('../assets/icons/chevron-right-bottom.svg') !important;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 1.95rem;
    transform: rotate(0deg);
    transition: transform .37s ease-in-out;
}


/* --- SPINNER --- */
.loading_spinner {
    width: 1.1rem;
    height: 1.1rem;
}

.loading_spinner_small {
    width: .8rem;
    height: .8rem;
    margin-left: 0.3rem;
}


/* ----------- FORM ----------- */
.text_error {
    color: var(--bc-red-2) !important;
}

.cursor-pointer {
    cursor: pointer;
}